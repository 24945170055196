import React from 'react';
import Project from '../Project';

export default function Portfolio() {
  return (
    <div>
      <h1 className='title'>Things I've made</h1>
        <Project/>
    </div>
  );
}