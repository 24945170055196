import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/FunLines.css'

function FunLines() {
    return (
        <div className="horizontal-rule-but-make-it-fashion">
        </div>
    );
}

export default FunLines;